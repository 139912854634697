<template>
  <div
      v-loading="!headTitle ? true : false"
      element-loading-background="rgba(255,255,255,1)"
      element-loading-spinner="el-icon-loading">
    <head-layout :head-title="headTitle" :head-btn-options="headBtnOptions" @head-romve="headRomve" @head-add="headAdd"></head-layout>
    <grid-head-layout ref="gridHeadLayout" :search-columns="searchColumns" @grid-head-search="gridHeadSearch" @grid-head-empty="gridHeadEmpty"></grid-head-layout>
    <grid-layout ref="gridLayOut" :table-options="tableOption"  :table-data="tableData" :table-loading="tableLoading" @grid-row-detail-click="rowView" @gird-handle-select-click="selectionChange" :data-total="page.total" :page="page" @page-current-change="onLoad" @page-size-change="onLoad">
      <template #customBtn="{row}">
        <el-button style="margin: 0 3px" v-if="row.actStatus != 'FINISHED' && row.startUserId == userInfo.user_id"  type="text" size="small" @click="rowEdit(row)">编辑</el-button>
        <el-button style="margin: 0 3px"  type="text" v-if="row.actStatus == 'FINISHED'" size="small" @click="rowView(row)">查看</el-button>
        <el-button style="margin: 0 3px" v-if="row.actStatus == 'PREPARE' && row.startUserId == userInfo.user_id"  type="text" size="small" @click="rowDel([row])">删除</el-button>
        <!--        <el-button style="margin: 0 3px"  v-if="row.actStatus != 'PREPARE'" type="text" size="small" @click="rowCopy(row)">复制</el-button>-->
      </template>
    </grid-layout>
  </div>
</template>
<script>
  import HeadLayout from "@/views/components/layout/head-layout";
  import GridLayout from "@/views/components/layout/grid-layout";
  import * as API from "@/api/securityLog/securityLog";

  import tableOption from "@/views/securityLog/tableOption";
  import {dictionaryBiz} from "@/api/reportTasks";
  import {mapGetters} from "vuex";
  export default {
    mixins: [tableOption],
    props: {
      securityLog: {
        type: Number,
        default: () => 1
      },
    },
    components: {
      GridLayout,
      HeadLayout
    },
    data() {
      return {
        searchColumns: [
          {
            label: "项目",
            prop: "projectId",
            type: "tree",
            dicUrl: "/api/sinoma-system/dept/tree",
            dicData: [],
            props: {
              label: "title",
              value: "id",
            },
            span: 4,
            placeholder: "请选择项目",
          },
        ],
        showPage: false,
        treeData: [],
        security_log: [],
        selectionList: [],
        isCycle: '',
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0,
        },
        query: {},
        headBtnOptions: [{
          label: "新增",
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: 'primary',
        }, {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "head-romve",
          type: "button",
          icon: ""
        }],
      }
    },
    computed: {
      ...mapGetters([
        'userInfo'
      ]),
      headTitle() {
        if (this.security_log.length == 0) return ''
        let {dictValue} = this.security_log.filter(item => item.dictKey == this.securityLog)[0]
        return dictValue
      },
      ids() {
        let ids = [];
        this.selectionList.forEach(ele => {
          ids.push(ele.id);
        });
        return ids.join(",");
      },

    },
    mounted() {
      this.onLoad(this.page, {});
      dictionaryBiz("security_log").then((res) => {
        this.security_log = res.data.data;
      });
    },
    methods: {
      headAdd() {
        this.$router.push({
          path: '/securityLog/without/edit',
          query: {
            type: 'add',
            securityLog: this.securityLog
          }
        })
      },
      // 搜索
      gridHeadSearch(searchForm) {
        const data = this.query
        if (searchForm.daterange) {
          searchForm.startTime = searchForm.daterange[0] + ' 00:00:00'
          searchForm.endTime = searchForm.daterange[1] + ' 23:59:59'
        } else {
          searchForm.checkStartTime = ''
          searchForm.checkEndTime = ''
        }
        this.page.currentPage = 1;
        this.query = {...data, ...searchForm};
        this.onLoad(this.page, this.query);
      },
      // 清空
      gridHeadEmpty(searchForm) {
        this.page.currentPage = 1;
        const data = this.query
        this.query = {...data, ...searchForm};
        this.onLoad(this.page, this.query);
      },
      // 选择列表数据
      selectionChange(list) {
        this.selectionList = list;
      },
      headRomve() {
        this.rowDel(this.selectionList)
      },
      rowDel(row) {
        this.selectionList = row
        if (this.selectionList.length === 0) {
          this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
          return;
        }
        this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            let formData = new FormData()
            formData.append('ids', this.ids)
            return API.remove(formData);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
          });
      },
      rowCopy(row) {
        this.$confirm('确定复制当前数据？', {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            return API.copy({
              id: row.id
            });
          })
          .then((res) => {
            this.rowEdit({id: res.data.data})
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
          });
      },
      // 获取列表数据
      onLoad(page, params) {
        this.page = page;
        this.tableLoading = true;
        delete params.daterange
        API.getList(page.currentPage, page.pageSize, {
          ...params,
          securityLog: this.securityLog,
          organizationId: this.userInfo.dept_id
        }).then((res) => {
          if (res.data.code == 200) {
            this.tableLoading = false;
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
            this.$refs.gridLayOut.page.total = res.data.data.total;
          }
        })
      },
      // 编辑
      rowEdit(row) {
        this.$router.push({
          path: '/hiddenTrouble/without/edit',
          query: {
            id: row.id,
            type: 'edit',
          }
        })
      },
      rowView(row) {
        this.$router.push({
          path: '/hiddenTrouble/without/edit',
          query: {
            id: row.id,
            type: 'view',
          }
        })
      },

    }
  }

</script>
